import { render, staticRenderFns } from "./RegisterPatient.vue?vue&type=template&id=620d8864&scoped=true&"
import script from "./RegisterPatient.vue?vue&type=script&lang=js&"
export * from "./RegisterPatient.vue?vue&type=script&lang=js&"
import style0 from "./RegisterPatient.vue?vue&type=style&index=0&id=620d8864&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620d8864",
  null
  
)

export default component.exports